import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_AUTH } from '@app/core/helpers/api_constant';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    public http: HttpClient
  ) { }

  login(email: string, password: string): Observable<any> {
    const jsonData = {
      "email": email,
      "password": password
    };

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    return this.http.post<any>(`${environment.API}${API_AUTH.LOGIN}`, jsonData, options);
  }
  // register(email: string, otp: string): Observable<any> {
  //   const jsonData = {
  //     "email": email,
  //     "otp": otp
  //   };

  //   let headers = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });
  //   let options = { headers: headers };

  //   return this.http.post<any>(`${environment.API}verify-account`, jsonData, options);
  // }
}
