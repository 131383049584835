import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { currentYear } from '@app/core/helpers/constants';
import { showAlertMessage } from '@app/core/helpers/utils';
import { AuthenticationService } from '@app/core/services/auth.service';
import { NgSelectModule } from '@ng-select/ng-select';

interface forgotPassEmail {
  email: string;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule]
})
export class ForgotPasswordComponent implements OnInit {

  forgotPassForm: FormGroup = new FormGroup({});
  submitted = false;
  isFormSubmitted: boolean = false;

  emailDetail?: forgotPassEmail;
  userData: any[] = [];
  currentYear: string = currentYear;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }
  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    this.forgotPassForm = new FormGroup({
      email: new FormControl(this.emailDetail?.email.trim(), [
        Validators.required,
        Validators.pattern(/^\s*[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})\s*$/)
      ]),
      userId: new FormControl(null)
    });
  }

  navigateToLogin() {
    this.router.navigate(['/account-login']);
  }

  onForgotPassSubmit() {
    this.isFormSubmitted = true;
    if (this.forgotPassForm.invalid) {
      this.forgotPassForm.markAllAsTouched();
      return;
    }
    this.submitted = true;
    const emailFromInput = this.forgotPassForm.value.email;
    const trimmedEmailValue = emailFromInput ? emailFromInput.trim() : null;
    const formData = {} as { email?: string, userId?: string };
    if (trimmedEmailValue) {
      formData['email'] = trimmedEmailValue;
    }
    if (this.forgotPassForm.value.userId) {
      delete formData['email']
      formData['userId'] = this.forgotPassForm.value.userId;
    }
    this.forgotPassForm.markAllAsTouched();
    this.forgotPassword(formData);
  }

  forgotPassword(loginDetail: any) {
    this.authenticationService.forgotPassword(loginDetail).subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.submitted = false;
          if (response.data) {
            this.forgotPassForm.controls['userId'].setValidators(
              Validators.compose([Validators.required]));
            this.forgotPassForm.get('email')?.setErrors(null);
            this.userData = response.data
            showAlertMessage(response.message ?? "Success", "success");
          } else {
            showAlertMessage(response.message ?? "Success", "success", () => this.router.navigate(['/account-login']));
          }
        }
      }, (error) => {
        this.submitted = false;
        showAlertMessage(error ?? "Something went wrong!", "error")
      }
    )
  }
}
