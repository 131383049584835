import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SubscribeSmsStoreService {
    private selectedOrganization: any = null;

    setSelectedOrg(org: any) {
        this.selectedOrganization = org;
    }

    getSelectedOrg() {
        return this.selectedOrganization;
    }

    clearSelectedOrg() {
        this.selectedOrganization = null;
    }
}
