<!-- <body class="flex items-center justify-center min-h-screen py-16 lg:py-10 bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public"> -->

<body
  class="flex min-h-screen lg:py-0 bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public">
  <div *ngIf="urlToken">
    <div
      role="status"
      class="flex items-center justify-center absolute left-0 right-0 top-0 bottom-0"
      style="background: rgba(0, 0, 0, 0.15)">
      <svg
        aria-hidden="true"
        class="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor" />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill" />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="flex flex-col w-full justify-center" *ngIf="!urlToken">
    <div class="grid grid-cols-12 gap-4 items-center justify-center">
      <div class="col-span-12 md:col-span-6 hidden md:block">
        <div class="hidden md:block pl-4">
          <div class="hero-image-container">
            <video
              class="hero-image mb-4"
              [autoplay]="true"
              [loop]="true"
              [muted]="true">
              <!-- <source src="../../../assets/images/promo-video.mp4" type="video/mp4"> -->
              <source
                src="https://firebasestorage.googleapis.com/v0/b/sketchplay-be1a2.appspot.com/o/sketchplay%2Fpromo-video.mp4?alt=media&token=7f1957cf-66b8-4bd6-bda1-54ab29718c8d"
                type="video/mp4" />
            </video>
          </div>
          <!-- <div class="hero-image-container">
            <img src="../../../assets/images/loginImg.png" alt="image 1" class="hero-image mb-4" />
          </div> -->
          <!-- <div class="hero-image-container relative hidden md:block">
            <img src="../../../assets/images/loginImg2.png" class="absolute top-6 left-6 h-18 w-18" />
            <img src="../../../assets/images/loginImg1.png" alt="image 2" class="hero-image" />
          </div> -->
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 !bg-[#111824]">
        <div
        class="mb-0 w-[371px] mx-auto lg:w-[371px] card shadow-none border-none !bg-[#111824] dark:!bg-[#111824] min-h-screen flex items-center">
          <div class="card-body w-full">
            <div class="flex justify-center">
              <span
                class="group-data-[sidebar-size=sm]:hidden font-kanit text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-bold italic text-white">
                SKETCHPLAY
              </span>
            </div>
            <form
              action=""
              class="mt-10"
              id="signInForm"
              [formGroup]="loginForm"
              (ngSubmit)="onSubmit()">
              <div
                class="hidden px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50"
                id="successAlert">
                You have <b>successfully</b> signed in.
              </div>
              <div class="mb-7">
                <input
                  type="text"
                  id="username"
                  formControlName="email"
                  class="form-input border border-input-border-color bg-input-bg-color text-[#94A3B8] py-3"
                  autocomplete="off"
                  placeholder="Enter your email..." />
                <div
                  *ngIf="
                    loginForm.get('email')?.invalid &&
                    (loginForm.get('email')?.dirty ||
                      loginForm.get('email')?.touched)
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="loginForm.get('email')?.errors?.['required']"
                    class="text-red-500 ml-1 mt-1">
                    Email is required.
                  </div>
                </div>
                <div
                  *ngIf="loginForm.get('email')?.invalid && isFormSubmitted"
                  class="invalid-feedback">
                  <div
                    *ngIf="loginForm.get('email')?.errors?.['pattern']"
                    class="text-red-500 ml-1 mt-1">
                    Please enter correct email address.
                  </div>
                </div>
              </div>
              <div class="mb-7">
                <div>
                  <div class="relative">
                    <input
                      type="password"
                      [type]="fieldTextType ? 'text' : 'password'"
                      formControlName="password"
                      id="password"
                      autocomplete="off"
                      class="form-input border border-input-border-color bg-input-bg-color text-[#94A3B8] py-3"
                      placeholder="Enter your password..." />
                    <i
                      class="ri-eye-off-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
                      (click)="toggleFieldTextType()"
                      *ngIf="!fieldTextType"></i>
                    <i
                      class="ri-eye-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
                      (click)="toggleFieldTextType()"
                      *ngIf="fieldTextType"></i>
                  </div>

                  <div
                    *ngIf="
                      loginForm.get('password')?.invalid &&
                      (loginForm.get('password')?.dirty ||
                        loginForm.get('password')?.touched)
                    "
                    class="invalid-feedback">
                    <div
                      *ngIf="loginForm.get('password')?.errors?.['required']"
                      class="text-red-500 ml-1 mt-1">
                      Password is required.
                    </div>
                  </div>
                  <div
                    *ngIf="
                      loginForm.get('password')?.invalid && isFormSubmitted
                    "
                    class="invalid-feedback">
                    <div
                      *ngIf="loginForm.get('password')?.errors?.['pattern']"
                      class="text-red-500 ml-1 mt-1">
                      Please enter correct password.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-2">
                    <input
                      id="checkboxDefault1"
                      class="size-4 border rounded-sm appearance-none bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500 checked:disabled:bg-custom-400 checked:disabled:border-custom-400"
                      type="checkbox"
                      (change)="onKeepMeLogIn($event)"
                      [checked]="rememberMe"
                      value="" />
                    <label
                      for="checkboxDefault1"
                      class="inline-block text-base font-regular align-middle cursor-pointer text-[#C9D8E9]">
                      Keep me logged in
                    </label>
                  </div>
                  <a
                    (click)="routeToForgotPass()"
                    class="font-regular transition-all duration-150 ease-linear text-[#C9D8E9] cursor-pointer">
                    Forgot Password
                  </a>
                </div>
                <div
                  id="remember-error"
                  class="hidden mt-1 text-sm text-red-500">
                  Please check the "Remember me" before submitting the form.
                </div>
              </div>
              <div class="mt-20">
                <button
                  type="submit"
                  class="w-full text-[#C9D8E9] font-normal btn bg-custom-500 border-custom-500 hover:text-[#C9D8E9] hover:bg-custom-600 hover:border-custom-600 focus:text-[#C9D8E9] focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                  Sign In
                </button>
              </div>
              <div class="mt-8 text-center">
                <p class="mb-28 text-[12px] text-[#C5D2E5]">
                  First Time Users <br />
                  <a
                    (click)="navigateToSignUp()"
                    class="font-bold cursor-pointer transition-all duration-150 ease-linear text-[#C9D8E9]">
                    Register Here</a
                  >
                </p>
                <p class="text-[#C9D8E9] text-[12px]">
                  © {{currentYear}} Crafted with
                  <i class="ri-heart-fill heart-icon"></i> by Lab3, Interactive,
                  inc.
                  <br />
                  DBA as
                  <a
                    href="https://sketchplay.com"
                    target="_blank"
                    class="font-semibold underline transition-all duration-150 ease-linear text-[#C9D8E9] dark:text-zink-200 dark:hover:text-custom-500">
                    Sketchplay.
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
