<form action="" class="mt-10" id="signInForm" [formGroup]="passwordForm">
  <div class="mb-7">
    <div>
      <div class="relative">
        <input type="{{ hidePasswordField ? 'password' : 'text' }}" formControlName="password" id="password"
          autocomplete="off"
          class="form-input border border-input-border-color rounded bg-input-bg-color text-[#94A3B8] text-[12px] py-3"
          placeholder="Type your password here…" autocomplete="off"
          [attr.aria-label]="hidePasswordField ? 'Show password' : 'Hide password'" />
        <i class="ri-eye-off-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
          (click)="togglePasswordVisibility()" *ngIf="hidePasswordField"></i>
        <i class="ri-eye-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
          (click)="togglePasswordVisibility()" *ngIf="!hidePasswordField"></i>
      </div>
      <div
        *ngIf=" passwordForm.get('password')?.invalid && (passwordForm.get('password')?.dirty || passwordForm.get('password')?.touched)"
        class="invalid-feedback">
        <div *ngIf="passwordForm.get('password')?.errors?.['required']" class="text-red-500 ml-1 mt-1">
          Password is required.
        </div>
        <div *ngIf="
          passwordForm.get('password')?.invalid &&
          (passwordForm.get('password')?.dirty && isFormSubmitted) 
        " class="invalid-feedback">
          <div *ngIf="passwordForm.get('password')?.errors?.['pattern']" class="text-red-500 ml-1 mt-1">
            Please enter correct password.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-7">
    <div>
      <div class="relative">
        <input type="{{ hideConfirmField ? 'password' : 'text' }}" formControlName="confirmPassword"
          id="confirmPassword" autocomplete="off"
          class="form-input border rounded border-input-border-color bg-input-bg-color text-[#94A3B8] text-[12px] py-3"
          placeholder="Confirm your password here…"
          [attr.aria-label]="hideConfirmField ? 'Show password' : 'Hide password'" />

        <i class="ri-eye-off-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
          (click)="toggleConfirmVisibility()" *ngIf="hideConfirmField"></i>
        <i class="ri-eye-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
          (click)="toggleConfirmVisibility()" *ngIf="!hideConfirmField"></i>
      </div>

      <div *ngIf="
        passwordForm.get('confirmPassword')?.invalid && (passwordForm.get('confirmPassword')?.dirty || passwordForm.get('confirmPassword')?.touched && isFormSubmitted)
      " class="invalid-feedback">
        <div *ngIf="passwordForm.get('confirmPassword')?.errors?.['required']" class="text-red-500 ml-1 mt-1">
          Confirm password is required.
        </div>
        <div *ngIf="passwordForm.get('confirmPassword')?.hasError('confirmPasswordValidator')"
          class="text-red-500 ml-1 mt-1">
          Password & Confirm Password do not match.
        </div>
      </div>
    </div>
  </div>
  <div class="password-strength">
    <div class="progress-bar-container">
      <div class="progress-bar" [style.width.%]="passwordStrength"></div>
    </div>
    <p class="text-[#94A3B8] flex justify-center">Password strength: {{ passwordStrength }}%</p>
  </div>

  <div class="password-rules">
    <div *ngFor="let rule of passwordRules" class="rule">
      <i
        class="ri-checkbox-circle-fill"
        [class.text-green-500]="rule.valid" [class.text-[#94A3B8]]="!rule.valid">
      </i>
      <span [class.text-green-500]="rule.valid" [class.text-[#94A3B8]]="!rule.valid">
        {{ rule.message }}
      </span>
    </div>
  </div>

  <div class="mt-32">
    <button
      class="w-full text-[#C9D8E9] font-normal btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
      (click)="emitStep(2)">
      {{ submitted ? 'Loading...' : 'Enter Security Code' }}
      </button>
  </div>
</form>