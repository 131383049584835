import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, catchError } from 'rxjs';
import { API_FILE_UPLOAD_AND_DOWNLOAD } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';
import { TokenStorageService } from './token-storage.service';
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  uploadFile(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);

    return this.http.post<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.UPLOAD_FILE}`, formdata).pipe(catchError(handleError))
  }

  uploadDocument(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);

    return this.http.post<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.UPLOAD_DOCUMENT}`, formdata).pipe(catchError(handleError))
  }

  uploadVideo(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);

    return this.http.post<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.UPLOAD_VIDEO}`, formdata).pipe(catchError(handleError))
  }

  uploadProfileImage(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);
    return this.http.post<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.UPLOAD_PROFILE_IMAGE}`, formdata).pipe(catchError(handleError))
  }

  uploadProfileImageWithUserId(payload: any, userId: number): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);
    return this.http.post<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.UPLOAD_PROFILE_IMAGE}?userId=${userId}`, formdata).pipe(catchError(handleError))
  }

  uploadBulkDocument(payload: any): Observable<any> {
    const formdata = new FormData();
    for (let i = 0; i < payload.length; i++) {
      formdata.append('files', payload[i]);
    }
    return this.http.post<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.UPLOAD_BULK_DOCUMENT}`, formdata).pipe(catchError(handleError))
  }

  uploadCSVData(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload?.file);
    return this.http.post<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.UPLOAD_CSV_DATA}?fileName=${payload?.fileName}`, formdata).pipe(catchError(handleError))
  }
  uploadCSVRoster(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload?.file);
    return this.http.post<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.UPLOAD_CSV_ROASTER}?teamId=${payload?.teamId}`, formdata).pipe(catchError(handleError))
  }

  downloadRosterTemplate(): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.DOWNLOAD_ROASTER_TEMPLATE}`).pipe(catchError(handleError))
  }

  downloadRosterTemplateByTeam(teamId: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.TEAM_ROASTER_TEMPLATE}/` + teamId).pipe(catchError(handleError))
  }

  downloadZip(payload: { documents: string[] | string, name?: string }): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.DOWNLOAD_ZIP}`, payload).pipe(catchError(handleError))
  }
  downloadUserByOrgId(orgId: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.DOWNLOAD_USER}?orgIds=` + orgId).pipe(catchError(handleError))
  }

  downloadRegisteredEventUsers(eventId: number): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.DOWNLOAD_REGISTERED_EVENT_USER}/${eventId}`).pipe(catchError(handleError))
  }

  exportRecipients(id: number, type: string): Observable<any> {
    const endpoint = type === 'send-sms' ? API_FILE_UPLOAD_AND_DOWNLOAD.EXPORT_RECIPIENTS_SMS : API_FILE_UPLOAD_AND_DOWNLOAD.EXPORT_RECIPIENTS
    return this.http.get<any>(`${environment.API}${endpoint}/${id}`).pipe(catchError(handleError))
  }

  exportCustomList(id: number): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.EXPORT_CUSTOM_LIST}/${id}`).pipe(catchError(handleError))
  }

  downloadPurchasedPackages(orgId?: any, userId?: any): Observable<any> {
    const params: string[] = [];

    if (orgId) {
      params.push(`orgIds=${orgId}`);
    }

    if (userId) {
      params.push(`userIds=${userId}`);
    }

    const url = `${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.DOWNLOAD_PURCHASE_PACK}${params.length > 0 ? `?${params.join('&')}` : ''}`;

    return this.http.get<any>(url).pipe(catchError(handleError));
  }
  downloadSingleEvents(orgId?: any, userId?: any): Observable<any> {
    const params: string[] = [];

    if (orgId) {
      params.push(`orgIds=${orgId}`);
    }

    if (userId) {
      params.push(`userIds=${userId}`);
    }

    const url = `${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.DOWNLOAD_SINGAL_EVENT}${params.length > 0 ? `?${params.join('&')}` : ''}`;

    return this.http.get<any>(url).pipe(catchError(handleError));
  }

  downloadPaymentPlans(orgId?: any, userId?: any): Observable<any> {
    const params: string[] = [];

    if (orgId) {
      params.push(`orgIds=${orgId}`);
    }

    if (userId) {
      params.push(`userIds=${userId}`);
    }

    const url = `${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.DOWNLOAD_PAYMENT_PLAN}${params.length > 0 ? `?${params.join('&')}` : ''}`;

    return this.http.get<any>(url).pipe(catchError(handleError));
  }

  downloadSKBucksHistoryByOrgId(orgId: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_FILE_UPLOAD_AND_DOWNLOAD.DOWNLOAD_SKUBUCKS_HISTORY}?orgIds=` + orgId).pipe(catchError(handleError))
  }
}
