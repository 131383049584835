import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_NOTIFICATION } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  private showNotificationSubject = new BehaviorSubject<any>(null);

  constructor(
    public http: HttpClient,
  ) { }

  createSendPushNotification(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_NOTIFICATION.SEND_NOTIFICATION}`, payload).pipe(catchError(handleError))
  }

  getNotifications(payload: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_NOTIFICATION.GET_NOTIFICATION}?skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`, payload).pipe(catchError(handleError))
  }

  readNotification(id: number): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_NOTIFICATION.READ_NOTIFICATION}/${id}?flag=true`).pipe(catchError(handleError))
  }

  getNotificationSettings(id: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_NOTIFICATION.GET_NOTIFICATION_SETTINGS}/${id}`).pipe(catchError(handleError))
  }

  updateNotificationSettings(payload: object): Observable<any> {
    return this.http.put(`${environment.API}${API_NOTIFICATION.UPDATE_NOTIFICATION_SETTINGS}`, payload).pipe(catchError(handleError))
  }

  public setUnReadFlag(isShowNotificationDot: boolean): void {
    this.showNotificationSubject.next(isShowNotificationDot);
  }

  getShowNotificationDotSubjectObservable() {
    return this.showNotificationSubject.asObservable();
  }
}
