import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, catchError, filter, finalize, switchMap, take, throwError } from 'rxjs';
import { AuthenticationService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private isRefreshing = false;

  constructor(
    private tokenStorage: TokenStorageService,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (request.url.includes('assets/i18n/')) {
    //   return next.handle(request); // Pass through without modifying
    // }
    const token = this.tokenStorage.getToken();

    // Add token to header
    if (token) {
      request = this.addTokenHeader(request, token);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle token expiration
          return this.handle401Error(request, next);
        }

        return throwError(() => error);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null); // Reset the refresh token observable

      const refreshToken = this.tokenStorage.getRefreshToken();

      if (refreshToken) {
        return this.authService.refreshToken({ refreshToken: refreshToken }).pipe(
          switchMap((response: any) => {
            if (response?.status) {
              // Save the new token
              const newToken = response.data?.token;
              localStorage.setItem('token', newToken);
              this.refreshTokenSubject.next(newToken); // Notify all queued requests
              return next.handle(this.addTokenHeader(request, newToken));
            } else {
              window.location.reload(); // Reload the page
              this.tokenStorage.signOut();
              return next.handle(request); // Continue without retry
            }
          }),
          catchError(() => {
            window.location.reload(); // Reload the page
            this.tokenStorage.signOut();
            this.refreshTokenSubject.next(null); // Notify failure
            return next.handle(request); // Continue without retry
          }),
          finalize(() => {
            this.isRefreshing = false;
          })
        );
      } else {
        window.location.reload(); // Reload the page
        this.tokenStorage.signOut();
        return next.handle(request); // Continue without retry
      }
    } else {
      // If already refreshing, wait for the refresh to complete
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null), // Wait until the token is refreshed
        take(1), // Only take the first emitted value
        switchMap((newToken: string | null) => {
          if (newToken) {
            return next.handle(this.addTokenHeader(request, newToken)); // Retry the original request
          } else {
            return next.handle(request); // Continue without retry
          }
        })
      );
    }
  }

}
