import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
  inject
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CutomDropdownComponent } from '@app-shared/component/customdropdown';
import { API_ORGANIZATION } from '@app/core/helpers/api_constant';
import { DataTableModel, DisplayBlock } from '@app/core/helpers/helper.classes';
import { checkDeviceBreakPoint, parentChildOrganization, showAlertMessage } from '@app/core/helpers/utils';
import { AuthenticationService } from '@app/core/services/auth.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { PushNotificationService } from '@app/core/services/push-notification.service';
import { SharedService } from '@app/core/services/shareData.service';
import { SidebarService } from '@app/core/services/sidebar.service';
import { TokenStorageService } from '@app/core/services/token-storage.service';
import { UserdataService } from '@app/core/services/userdata.service';
import { MDModalModule, MDModalsComponent } from '@app/shared/component/modals';
import { ModalService } from '@app/shared/component/modals/modal.service';
import { NGXPagination } from '@app/shared/component/pagination';
import { ImageCropperComponent } from '@app/shared/image-cropper/image-cropper.component';
import { _DatatableComponent } from '@app/shared/table/datatable/datatable.component';
import { MenuState, removeMenuData, storeMenuData } from '@app/store/Event/menus.reducer';
import { Store } from '@ngrx/store';
//import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ColumnMode, NgxDatatableModule } from '@siemens/ngx-datatable';
import {
  LUCIDE_ICONS,
  LucideAngularModule,
  LucideIconProvider,
  icons
} from 'lucide-angular';
import { NgOtpInputComponent, NgOtpInputModule } from 'ng-otp-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { SimplebarAngularModule } from 'simplebar-angular';
//import { LanguageService } from '../../core/services/language.service';
import { getLayout, getSidebarsize } from '../../store/layout/layout-selector';
import { ChildCommunityComponent } from './child-community/child-community.component';
import { ClickControlService } from './clickControlService';
import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { SwalService } from './swalService';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    SimplebarAngularModule,
    CutomDropdownComponent,
    //TranslateModule,
    NgxDatatableModule,
    NGXPagination,
    RouterModule,
    MDModalModule,
    _DatatableComponent,
    LucideAngularModule,
    MDModalModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    FormsModule,
    ChildCommunityComponent,
    ImageCropperComponent
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: LUCIDE_ICONS,
      multi: true,
      useValue: new LucideIconProvider(icons),
    },
    // LanguageService,
  ],
})
export class SidebarComponent implements OnInit {

  rows: any = [];

  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalItems: number = 0;
  startIndex: number = 0;
  endIndex: any;

  rowLimit: number = 5;
  filteredRows: any = []; // Filtered data source
  searchQuery: string = ''; // Search query entered by the user
  ColumnMode = ColumnMode;

  columns = [{
    name: 'name',
    label: 'Name',
  }]

  dataTableModel: DataTableModel = new DataTableModel();
  _displayBlock!: DisplayBlock;
  isSearchingOrganization: boolean = false;

  accessCodeForm: FormGroup = new FormGroup({});
  viewCommunityListForm: FormGroup = new FormGroup({});

  @ViewChild('communityPopup') popupCommunity: MDModalsComponent | any;
  @ViewChild('childCommunityPopup') childCommunityPopup: MDModalsComponent | any;
  @ViewChild('viewCommunityList') viewCommunityComponent: MDModalsComponent | any;
  @ViewChild('ViewMore') ViewMore: MDModalsComponent | any;

  // @ViewChild('ngOtpInput', { static: false }) ngOtpInputJoinCommunity: any;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInputJoinCommunity: NgOtpInputComponent | any;

  isOtpCorrect: boolean = false;
  isViewCommunityOpenClicked: boolean = false;
  isLoading: boolean = false;
  accessCode: string = "";
  roleAllData: any;
  accessCodeOnVerify: any;
  organizationsIdOnVerifyAccessCode: any;
  selectedRole: any = '';
  step: number = 0;
  menuItems: any = [];
  menus: any;
  isMoreMenu: boolean = false;
  navData: any;
  navbarMenuItems: any = [];
  layout: any;
  size: any;
  activeLink: string = '';
  orgActiveLink: string = 'Sketchplay';
  subOrgActiveLink: string = '';

  organizationId?: number
  icon = [
    { key: 'teammanagement', value: 'assets/images/sketchplay/teams.svg', link: '#' },
    { key: 'tournaments', value: 'assets/images/sketchplay/tournament.svg', link: '#' },
    { key: 'leaguemanagement', value: 'assets/images/sketchplay/cup.svg', link: '#' },
    { key: 'camps', value: 'assets/images/sketchplay/teams.svg', link: 'camps' },
    { key: 'clinics', value: 'assets/images/sketchplay/tournament.svg', link: '#' },
    { key: 'dailytraining', value: 'assets/images/sketchplay/cup.svg', link: '#' },
    { key: 'filmroom', value: 'assets/images/sketchplay/evaluations.svg', link: '#' },
    { key: 'calendar', value: 'assets/images/sketchplay/calender.svg', link: '#' },
    { key: 'evaluations', value: 'assets/images/sketchplay/evaluations.svg', link: '#' },
    { key: 'howto', value: 'assets/images/sketchplay/evaluations.svg', link: '#' },
    // { key: 'howto', value: 'assets/images/sketchplay/question.svg' },
    { key: 'settings', value: 'assets/images/sketchplay/setting.svg', link: '#' },
    { key: 'contact', value: 'assets/images/sketchplay/teams.svg', link: '#' },
  ];
  communities: any = []
  private store = inject(Store);
  organizations: any = [];
  setIcon: any;
  userDetails: any;
  selectedId: any;
  filteredCommunities: any[] = [];
  originalCommunities: any[] = [];
  activeLinkforOrganization: any;
  isSelectOrganization: boolean = false;
  storedActiveLink: any;
  skechplayData: any;
  searchText: any = '';
  isViewMore: boolean = false;
  routeName: string | undefined;
  showModal: boolean = false
  isShowChildCommunitybtn: boolean = false
  isMobileView: boolean = false;
  isShowHideChildOrgMenu: boolean = false;
  isEnableShop: boolean = true;

  constructor(
    //public translate: TranslateService,
    private sidebarService: SidebarService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private swalService: SwalService,
    private globalClickControlService: ClickControlService,
    private route: ActivatedRoute,
    private clickControlService: ClickControlService,
    private sharedService: SharedService,
    private ngxSpinnerService: NgxSpinnerService,
    private userdataService: UserdataService,
    private localStorageService: LocalStorageService,
    private modalService: ModalService,
    private tokenStorageService: TokenStorageService,
    private renderer: Renderer2,
    private pushNotificationService: PushNotificationService,
    private menu: Store<{ menu: MenuState }>

  ) {
    this.sidebarService.menuItems$.subscribe((menuItems) => {
      this.menuItems = menuItems;
    });
    this.sidebarService.featureItems$.subscribe((featureItems) => {
      this.menus = featureItems;
    });
    const userDataString = localStorage.getItem('currentUser');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      this.organizations = userData.organizations;
    }

    // translate.setDefaultLang('sp');
    this.filteredCommunities = this.communities;
    this.dataTableBindind();

  }

  dataTableBindind() {
    this._displayBlock = {
      getUrl: API_ORGANIZATION.GET_PUBLIC_ORGANIZATION,
      isQueryParam: true,
      queryparam: '?',
      isServerSidePagination: true,
      isAllowPaging: true,
      isFilterOption: false,
      isComingFrom: "sidebar",
      isAddBtn: false,
      sortOrder: [
        [0, 'asc'],
        [1, 'asc'],
      ],
      isShowPaginationInfo: true,
      isSearching: this.isViewMore ? false : true,
      perPage: 5,
      headerHeight: 0,
      rows: [
        {
          name: 'name',
          label: 'Client Name',
          type: 'CustomOrgView',
          colSize: '200',
          sorting: true,
          pipe: 'title',
        },
      ],
      options: {
        isGenerate: true
      },
    };
    this.dataTableModel.displayBlock = this._displayBlock;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (document.documentElement.getAttribute('data-layout') == 'horizontal') {
      if (document.documentElement.clientWidth >= 1025) {
        setTimeout(() => {
          this.updateMenu();
        }, 500);
      }
    }
  }

  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    letterCase: 'Upper' as const

  };
  onImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/placeholder.png';
  }

  onImageSubOrgError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/sketchplay-logo.png';
  }

  ngOnInit(): void {
    this.isMobileView = checkDeviceBreakPoint()['isMobile'];
    // Get Layout
    this.store.select(getLayout).subscribe(data => {
      this.layout = data;
      if (this.layout == 'horizontal') {
        setTimeout(() => {
          this.updateMenu();
        }, 1500);
      } else {
        //else part
      }
    });
    this.userMe();
    this.getCommunity();
    this.getAllRoles();

    // Get size
    this.store.select(getSidebarsize).subscribe(data => {
      this.size = data;
    });

    this.navData = MENU;

    this.localStorageService.getLocalStorageObservable().subscribe(value => {
      if (value === 'Clinics & Showcases' || value === 'Camps' || value === 'Leagues') {
        this.activeLink = value;
      }
    });

    this.localStorageService.getLocalStorageOrganizationObservable().subscribe(value => {
      if (value) {
        this.orgActiveLink = value;
      }
    });

    this.storedActiveLink = localStorage.getItem('activeLink');
    const storedActiveOrganizatoin = localStorage.getItem('activeOrganization');
    if (this.storedActiveLink) {
      this.activeLink = this.storedActiveLink;
    }
    if (storedActiveOrganizatoin) {
      this.orgActiveLink = storedActiveOrganizatoin;
    }
  }

  ngAfterViewInit() {
    if (this.layout == 'horizontal') {
      setTimeout(() => {
        this.updateMenu();
      }, 1500);
    } else {
      // this.menuItems = MENU;
    }
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }

  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  // API Call
  // ####################################################

  getCommunity() {
    this.ngxSpinnerService.show();
    this.sidebarService.communityList(0, 100, '').subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      // let communities = response.data;
      this.communities = response?.data?.docs
    });
  }

  getAllRoles() {
    this.ngxSpinnerService.show();
    this.sidebarService.getAllRoles().subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      this.roleAllData = response.data;
    });
  }

  userMe() {
    this.menuItems = [];
    this.authenticationService.userMe().subscribe((response: any) => {
      const userData = response.data;
      this.userdataService.setUserData(userData)
      localStorage.setItem('userDetailsFromUserMe', JSON.stringify(response.data));
      this.organizations = userData.organizations

      this.skechplayData = this.organizations[0]
      const urlFrom = localStorage.getItem('urlFrom');
      if (urlFrom) {
        const org = userData?.organizations?.find((x: any) => x.name === urlFrom)
        if (org?.id) {
          this.orgActiveLink = urlFrom;
          localStorage.setItem('activeOrganization', urlFrom)
          localStorage.setItem('activeLinkforOrganization', org?.id);
          this.organizationId = org?.id;
          this.sharedService.emitClickEvent();
          //commenting for future purpose
          // if (Number(this.organizationId) !== 1) {
          this.getFeatures(this.organizationId);
          this.getActiveFeatures(this.organizationId);
          // }
        } else {
          this.setActiveLink(null, this.organizations[0]?.name)
          localStorage.setItem('activeOrganization', this.organizations[0]?.name)
          localStorage.setItem('activeLinkforOrganization', this.organizations[0]?.id);
          localStorage.setItem('organizationId', this.organizations[0]?.id)
          this.organizationId = this.organizations[0]?.id;
          // if (Number(this.organizationId) !== 1) {
          this.getFeatures(this.organizationId);
          this.getActiveFeatures(this.organizationId);
          // }
        }
        this.activeLink = '';
        localStorage.setItem('urlFrom', "");
      } else {
        if (this.isSelectOrganization) {
          let organizationId = this.organizations[1]?.id;
          this.orgActiveLink = this.organizations[1]?.name
          localStorage.setItem('activeOrganization', this.orgActiveLink)
          localStorage.setItem('activeLinkforOrganization', this.organizations[1]?.id);
          this.sharedService.emitClickEvent();
          // if (Number(organizationId) !== 1) {
          this.getFeatures(organizationId);
          this.getActiveFeatures(organizationId);
          // }
        }
        else {
          const link: any = localStorage.getItem('activeOrganization');
          if (link) {
            this.orgActiveLink = link;
            const id = localStorage.getItem('activeLinkforOrganization');
            const bindParentChildOrg = parentChildOrganization(this.organizations)
            const currentOrg = bindParentChildOrg?.find((x: any) => x.id == id)
            if (currentOrg?.isSubOrganization) {
              // if (Number(currentOrg?.parentOrgId) !== 1) {
              this.getFeatures(currentOrg?.parentOrgId);
              this.getActiveFeatures(currentOrg?.parentOrgId);
              // }
            } else {
              // if (Number(id) !== 1) {
              this.getFeatures(id);
              this.getActiveFeatures(id);
              // }
            }
          } else {
            this.orgActiveLink = this.organizations[0]?.name;
            localStorage.setItem('organizationId', this.organizations[0]?.id)
            localStorage.setItem('activeLinkforOrganization', this.organizations[0]?.id);
            this.organizationId = this.organizations[0]?.id;
            // if (Number(this.organizationId) !== 1) {
            this.getFeatures(this.organizationId);
            this.getActiveFeatures(this.organizationId);
            // }
            this.activeLink = '';
          }
          // this.organizations[0]?.name;
        }
      }
      this.sidebarService.updateMenuItems(this.organizations);
      if (this.menuItems.length > 10) {
        this.rows = this.menuItems.slice(10, this.menuItems.length);
        this.filteredRows = [...this.rows];
        this.totalItems = this.filteredRows.length;
      }
    });
  }

  moveMenuItemToTop(id: number): void {
    const index = this.menuItems.findIndex((item: { id: number; }) => item.id === id);
    if (index !== -1) {
      const [item] = this.menuItems.splice(index, 1); // Remove the item from its current position
      // this.menuItems.unshift(item); // Add the item to the beginning of the array
      this.menuItems.splice(1, 0, item); // Add the item to the second position (index 1) of the array

      if (this.menuItems.length > 10) {
        this.rows = this.menuItems.slice(10, this.menuItems.length);
        this.filteredRows = [...this.rows];
        this.totalItems = this.filteredRows.length;
      }

    }
  }

  // onPageChange(pageNumber: number): void {
  //   this.currentPage = pageNumber;
  //   this.updatePagedOrders();
  // }

  // Function to filter data based on search query
  updateFilter() {
    this.filteredRows = this.rows.filter((row: { name: string; }) =>
      row.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );

    if (this.filteredRows?.length === this.rows?.length || this.searchQuery.length > 0) {
      this.startIndex = 0;
      this.currentPage = 1;
    }
    this.totalItems = this.filteredRows.length;
  }

  verifyAccessCode() {
    this.ngxSpinnerService.show();
    this.sidebarService.checkAccessCode(this.accessCodeOnVerify).subscribe((res: any) => {
      if (res?.status) {
        this.ngxSpinnerService.hide();
        this.organizationsIdOnVerifyAccessCode = res.data.id;
        this.orgActiveLink = this.organizations[1]?.name
        this.step = 1;
        this.isSelectOrganization = true;
      }
    }, (res) => {
      this.ngxSpinnerService.hide();
      showAlertMessage(res ?? "Something went wrong!", "error", () => { this.ngOtpInputJoinCommunity.setValue(''); })
    })
  }

  getFeatures(orgId: any) {
    this.menu.dispatch(removeMenuData());
    this.ngxSpinnerService.show();
    this.menus = [];
    this.sidebarService.featureList(orgId).subscribe((response) => {
      this.ngxSpinnerService.hide();
      if (response?.data) {
        response.data.forEach((category: any) => {
          if (category.isTitle) {
            const titleItem = {
              id: category.id,
              name: category.name,
              isTitle: true,
            };
            this.menus.push(titleItem);
            category.list.forEach((item: any) => {
              const icon = this.icon.find((x) => x.key == item.id);
              const menuItem = {
                id: item.id,
                name: item.name,
                icon: icon ? icon?.value : '',
                link: orgId == '1' ? '' : icon?.link,
              };
              this.menus.push(menuItem);
              let shop = this.menus?.find((x: any) => x.id == 'shop');
              if (shop) {
                this.isEnableShop = true;
              } else {
                this.isEnableShop = false;
              }
            });
          }
        });
        this.menu.dispatch(storeMenuData({ payload: this.menus }));
        this.sidebarService.updateFeatureItems(this.menus);
      }
    });
  }

  // INITIAL CLICK
  // ####################################################

  async onClickViewMore(event: any) {
    event.preventDefault();
    this.searchQuery = '';

    if (this.menuItems.length > 10) {
      this.rows = this.menuItems.slice(10, this.menuItems.length);
      this.filteredRows = [...this.rows];
      this.totalItems = this.filteredRows.length;
    }
    this.currentPage = 1;

    this.isViewMore = true;
    const isClickDisabled = this.globalClickControlService.isClickDisabled();

    // Check if click is disabled or show confirmation dialog
    if (!isClickDisabled || (isClickDisabled && await this.showConfirmationDialog())) {
      // Perform common actions
      this.ViewMore.open();
    } else {

    }
  }

  async openCommunity(event: any) {
    event.preventDefault();

    const isClickDisabled = this.globalClickControlService.isClickDisabled();

    // Check if click is disabled or show confirmation dialog
    if (!isClickDisabled || (isClickDisabled && await this.showConfirmationDialog())) {


      // Perform common actions
      this.isOtpCorrect = false
      this.popupCommunity.open();
      this.selectedRole = "";
    } else {

    }
  }

  async openChildCommunity() {
    this.childCommunityPopup.open();
  }

  async setActiveLink(event: any, rowItem: any) {
    if (this.isMobileView) {
      this.renderer.addClass(document.body, 'no-scroll');
    }
    event?.preventDefault();
    this.getAllNotifications();
    const isClickDisabled = this.globalClickControlService.isClickDisabled();

    // Check if click is disabled or show confirmation dialog
    if (!isClickDisabled || (isClickDisabled && await this.showConfirmationDialog())) {
      // Perform common actions
      this.activeLink = rowItem?.name;
      this.selectedId = rowItem?.id;
      //localStorage.setItem('activeOrganization', label)
      localStorage.setItem('activeLink', rowItem?.name);
      const orgId = localStorage.getItem("activeLinkforOrganization")
      if (orgId === "1") {
        this.popupCommunity.open();
      } else {
        if (rowItem?.name === 'Camps') {
          this.router.navigate(['/camps']);
        }
        if (rowItem?.name === 'Clinics & Showcases') {
          this.router.navigate(['/clinics']);
        }
        if (rowItem?.id === 'dailytraining') {
          this.router.navigate(['/dailytraining']);
        }
        if (rowItem?.id === 'leaguemanagement') {
          this.router.navigate(['/leagues']);
        }
        if (rowItem?.id === 'teammanagement') {
          this.router.navigate(['/teams']);
        }
      }
    }
  }

  async renderHome() {
    const isClickDisabled = this.globalClickControlService.isClickDisabled();

    // Check if click is disabled or show confirmation dialog
    if (!isClickDisabled || (isClickDisabled && await this.showConfirmationDialog())) {
      this.router.navigate(['/']);
    }
  }
  async showConfirmationDialog(): Promise<boolean> {
    return new Promise((resolve) => {
      this.swalService.conformationPopup('You will lose any progress by navigating away from this page.', 'error', () => {
        resolve(true);
      }, () => {
        resolve(false);
      }, '', 'Ok, leave anyway', 'Cancel');
    });
  }

  async setActiveLinkfprOrganization(rowItem: any, index: number) {
    if (this.isMobileView) {
      this.renderer.addClass(document.body, 'no-scroll');
    }
    this.getAllNotifications();
    //event.preventDefault();
    const isAdmin = rowItem?.roles.find((x: any) => x.type === 'admin')
    this.isShowChildCommunitybtn = isAdmin ? true : false

    const isClickDisabled = this.globalClickControlService.isClickDisabled();
    this.subOrgActiveLink = ''
    // Check if click is disabled or show confirmation dialog
    if (!isClickDisabled || (isClickDisabled && await this.showConfirmationDialog())) {
      // Perform common actions
      this.orgActiveLink = rowItem?.name;
      this.activeLink = rowItem?.name;
      this.selectedId = rowItem?.id;
      // this.activeLink = ''
      localStorage.setItem('activeLinkforOrganization', rowItem?.id);
      localStorage.setItem('activeOrganization', rowItem?.name);
      // if (Number(rowItem?.id) !== 1) {
      this.getFeatures(rowItem?.id);
      this.getActiveFeatures(rowItem?.id);
      // }
      this.router.navigate(['/dashboard']);
      this.sharedService.emitClickEvent();

      if (index === 0) {
        // this.ngxSpinnerService.show();
        this.sidebarService.viewMoreOrganizationOnTop({ id: rowItem?.id }).subscribe((res: any) => {
          if (res.status) {
            //this.userMe();
            this.moveMenuItemToTop(rowItem?.id);
          }
        }
          , (res) => {
            // for error display, used common code.
            // showAlertMessage(res, "error");
            // this.ngxSpinnerService.hide();
          })
      }
      this.ViewMore.close();
    }
  }

  async setActiveLinkforSubOrganization(rowItem: any, index: number) {
    //event.preventDefault();
    const isClickDisabled = this.globalClickControlService.isClickDisabled();
    this.getAllNotifications();
    // Check if click is disabled or show confirmation dialog
    if (!isClickDisabled || (isClickDisabled && await this.showConfirmationDialog())) {
      // Perform common actions
      this.subOrgActiveLink = rowItem?.name;
      this.activeLink = rowItem?.name;
      //this.selectedId = rowItem?.id;
      // this.activeLink = ''
      localStorage.setItem('activeLinkforOrganization', rowItem?.id);
      localStorage.setItem('activeOrganization', rowItem?.name);
      //this.getFeatures(rowItem?.id);
      this.router.navigate(['/dashboard']);
      this.sharedService.emitClickEvent();

      // if (index === 0) {
      //   // this.ngxSpinnerService.show();
      //   this.sidebarService.viewMoreOrganizationOnTop({ id: rowItem?.id }).subscribe((res: any) => {
      //     if (res.status) {
      //       //this.userMe();
      //       this.moveMenuItemToTop(rowItem?.id);
      //     }
      //   }, (res) => {
      //     showAlertMessage(res, "error");
      //     // this.ngxSpinnerService.hide();
      //   })
      // }
      this.ViewMore.close();
    }
  }

  hideSidebar() {
    this.renderer.removeClass(document.body, 'no-scroll');
    const sidebarOverlay = document.getElementById('sidebar-overlay') as any;
    sidebarOverlay.classList.add('hidden');
    document.documentElement
      .querySelector('.app-menu')
      ?.classList.add('hidden');
    document.body.classList.remove('overflow-hidden');
  }

  isPointerEventsDisabled() {
    return false;
    //return this.globalClickControlService.isClickDisabled();
  }

  // SUB CLICK
  // ####################################################

  onOtpChange(accessCode: any) {
    this.accessCodeOnVerify = accessCode.toUpperCase();
    if (accessCode.length === 6) {
      this.isOtpCorrect = true;
    } else {
      this.isOtpCorrect = false;
    }
  }

  selectRole(role: string) {
    this.selectedRole = role;
  }

  isSelected(role: string): boolean {
    return this.selectedRole === role;
  }

  goToChooseRole() {
    this.step = 1;
  }

  goToChooseRoleOnOpenCommunity(communities: any) {
    this.step = 3;
    this.organizationsIdOnVerifyAccessCode = communities?.id
    this.isSelectOrganization = true
  }

  joinOrganization() {
    this.ngxSpinnerService.show();
    this.sidebarService.joinPrivateOrganization({ organization: this.organizationsIdOnVerifyAccessCode, role: this.selectedRole }).subscribe((res: any) => {
      if (res.status) {
        localStorage.setItem('token', res?.data?.token)
        this.ngxSpinnerService.hide();
        this.popupCommunity.close();
        this.viewCommunityComponent.close();

        showAlertMessage(res?.message, "success", () => {
          this.router.navigate(['/dashboard'])
        })
        this.userMe();
        this.step = 0;
      }
      this.closeCommunityPopUp();
    }, (res) => {
      showAlertMessage(res, "error");
      this.ngxSpinnerService.hide();
    })
  }

  closeCommunityPopUp() {
    // this.searchSubject.next('');
    this.popupCommunity.close();
    this.viewCommunityComponent.close();
    this.step = 0;
    this.accessCodeOnVerify = "";
    this.ngOtpInputJoinCommunity?.setValue(this.accessCodeOnVerify);
  }

  // Display Menu
  updateMenu() {
    const isMoreMenu = false;
    const navbarHeader = document.querySelector('.navbar-header');
    const navbarNav = document.getElementById('navbar-nav') as any;

    // count width of horizontal menu
    const fullWidthOfMenu = navbarHeader!.clientWidth - 150;

    const menuWidth = fullWidthOfMenu || 0;
    let totalItemsWidth = 0;
    const visibleItems: any = [];
    const hiddenItems: any = [];

    const moreMenuItem = {
      id: 'more',
      label: 'more',
      icon: 'network',
      subItems: null,
      link: 'sidebarMore',
      stateVariables: isMoreMenu,
      click: (e: any) => {
        e.preventDefault();
        this.isMoreMenu = !this.isMoreMenu;
      },
    };

    for (let i = 0; i < this.navData.length; i++) {
      const itemWidth = navbarNav?.children[i]?.offsetWidth;
      totalItemsWidth += itemWidth;

      if (totalItemsWidth <= menuWidth - 50 || window.innerWidth < 768) {
        visibleItems.push(this.navData[i]);
      } else {
        if (!this.navData[i].isTitle) {
          hiddenItems.push(this.navData[i]);
        }
      }
      if (i + 1 === this.navData.length) {
        moreMenuItem.subItems = hiddenItems;
      }
    }

    // const updatedMenuItems = hiddenItems.length > 0 ? [...visibleItems, moreMenuItem] : visibleItems;
    // this.menuItems = updatedMenuItems;
  }

  viewCommunityPopUps() {
    this.popupCommunity.close();
    this.viewCommunityComponent.open();
    this.step = 2;
  }

  // Pagination
  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.updatePagedOrders();
  }

  getEndIndex() {
    return Math.min(this.startIndex + this.itemsPerPage, this.totalItems)
  }

  updatePagedOrders(): void {
    this.startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.endIndex = this.startIndex + this.itemsPerPage;

    this.filteredRows = this.rows.filter((row: { name: string; }) =>
      row.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );

    this.filteredRows = this.filteredRows.slice(this.startIndex, this.endIndex);
    //this.updateFilter();
  }

  openModal() {
    this.showModal = true
    window.document.body.style.overflow = 'hidden'
    this.modalService.open('childCommunityPopup');
  }

  closeModal(response: any) {
    this.showModal = false
    window.document.body.style.overflow = ''
    this.modalService.close('childCommunityPopup');
    if (response?.isSubmit) {
      showAlertMessage(response?.response?.message ?? "Success", "success", () => {
        this.userMe();
      });
    }
  }

  onShop() {
    const orgId = localStorage.getItem("activeLinkforOrganization")
    if (orgId === "1") {
      this.popupCommunity.open();
    } else {
      this.activeLink = 'Shop';
      localStorage.setItem('activeLink', 'Shop');
      this.router.navigate(['/purchase-product']);
    }

  }

  showHideChildOrgMenu() {
    this.isShowHideChildOrgMenu = !this.isShowHideChildOrgMenu;
  }

  getAllNotifications() {
    const payload = {
      skip: 0,
      limit: 1000,
      isActive: true
    }
    this.pushNotificationService.getNotifications(payload).subscribe((response: any) => {
      if (response?.status) {
        this.pushNotificationService.setUnReadFlag(response?.data?.unReadCount > 0 ? true : false);
        let userDetail: any
        this.userdataService.userData$.subscribe(userData => {
          userDetail = userData;
        });
        const differentUser = response?.data?.docs[0]?.userId?.id !== userDetail?.id;
        if (differentUser) {
          localStorage.setItem('activeOrganization', 'Sketchplay')
          localStorage.setItem('activeLinkforOrganization', '1');
          this.userMe();
          this.sharedService.emitClickEvent();
        }
      }
    });
  }

  getActiveFeatures(orgId: any) {
    this.sidebarService.getActiveFeatures(orgId).subscribe((response) => {
      if (response?.status) {
        this.sidebarService.updateActiveFeatures(response?.data);
      } else {
        this.sidebarService.updateActiveFeatures(null);
      }
    });
  }
}
