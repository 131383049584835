import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_LEAGUE } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';

interface IListPayload { orgId: number; skip: number; limit: number; isActive: boolean }

@Injectable({
  providedIn: 'root'
})
export class LeagueService {

  constructor(
    public http: HttpClient,
  ) { }

  getLeagueList(payload: any) {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.LEAGUE}?orgIds=${payload?.orgIds}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(handleError));
  }

  getLeagueLocations(payload: IListPayload): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.LEAGUE_LOCATION}?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(handleError))
  }

  createLeagueLocation(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.LEAGUE_LOCATION}`, payload).pipe(catchError(handleError))
  }

  getLeagueDivison(payload: IListPayload): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.LEAGUE_DIVISION}?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(handleError))
  }

  createLeagueDivision(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.LEAGUE_DIVISION}`, payload).pipe(catchError(handleError))
  }

  // getLeagueDocument(payload: IListPayload): Observable<any> {
  //   return this.http.get<any>(`${environment.API}league/document?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(handleError))
  // }

  getLeagueDocument(): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.GET_LEAGUE_DOCUMENT}`).pipe(catchError(handleError))
  }

  // createLeagueDocument(payload: any): Observable<any> {
  //   return this.http.post<any>(`${environment.API}league/document`, payload).pipe(catchError(handleError))
  // }

  createLeagueDocument(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.CREATE_LEAGUE_DOCUMENT}`, payload).pipe(catchError(handleError))
  }

  createLeague(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.LEAGUE}`, payload).pipe(catchError(handleError))
  }

  updateLeague(payload: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}${API_LEAGUE.LEAGUE}/${payload?.id}`, payload).pipe(catchError(handleError))
  }

  getLeagueById(id: number) {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.LEAGUE}/${id}`).pipe(catchError(handleError));
  }

  getLeagueAgeGroup(payload: IListPayload): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.GET_LEAGUE_AGE_GROUP}?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(handleError))
  }

  createLeagueAgeGroup(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.GET_LEAGUE_AGE_GROUP}`, payload).pipe(catchError(handleError))
  }

  getTeamNotRegisterInLeague(leagueId: number) {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.TEAM_NOT_REGISTER_IN_LEAGUE}/${leagueId}`).pipe(catchError(handleError));
  }

  teamApprovalRequest(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.TEAM_APPROVAL_REQUEST}`, payload).pipe(catchError(handleError))
  }

  getTeamDetail(id: number) {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.TEAM_DETAIL}/${id}`).pipe(catchError(handleError));
  }

  getLeaguePaymentData(payload: any) {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.LEAGUE_PAYMENT_DATA}`, payload);
  }

  leaguePaymentIntent(payload: any) {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.LEAGUE_INTENT_NO_AUTH}`, payload).pipe(catchError(handleError))
  }

  leaguePayment(payload: any) {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.LEAGUE_PAYMENT_NO_AUTH}`, payload).pipe(catchError(handleError))
  }
  sendInvoice(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.SEND_INVOICE}`, payload).pipe(catchError(handleError))
  }

  getLeagueDocumentsById(leagueId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.GET_LEAGUE_DOCUMENT_BY_ID}/${leagueId}`).pipe(catchError(handleError))
  }

  getLeagueAgeGroupByLeagueId(leagueId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.GET_LEAGUE_AGE_GROUP_BY_ID}/${leagueId}`).pipe(catchError(handleError))
  }

  createLeagueTeamDocument(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.CREATE_LEAGUE_TEAM_DOCUMENT}`, payload).pipe(catchError(handleError))
  }

  getLeagueTeamDocument(payload: IListPayload): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.CREATE_LEAGUE_TEAM_DOCUMENT}?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}`).pipe(catchError(handleError))
  }

  updateTeamStatus(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.UPDATE_TEAM_STATUS}`, payload).pipe(catchError(handleError))
  }

  getExportedData(payload: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.LEAGUE_EXPORTED_DATA}/${payload?.teamId}`).pipe(catchError(handleError))
  }

  getLeagueDivisionByLeagueId(leagueId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.LEAGUE_DIVISION}/${leagueId}`).pipe(catchError(handleError))
  }

  getDivisionFilterData(payload: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.DIVISION_FILER_DATA}/${payload?.leagueId}?divisionId=${payload?.divisionId}`).pipe(catchError(handleError))
  }
}
