
export const API_AUTH = {
  SIGN_UP: 'sign-up',
  LOGIN: 'web-login',
  USER_AUTHENTICATION: 'users/authenticate',
  ACCOUNT_VERIFICATION: 'verify-account',
  CHECK_EMAIL: 'check-email-phone',
  RESEND_OTP: 'resend-otp',
  CHANGE_PASSWORD: 'change-password',
  FORGOT_PASSWORD: 'forgot-password',
  GET_ME: 'me',
  REFRESH_TOKEN: 'refresh-token'
}

export const API_ADMIN_CENTER = {
  GET_USER_LIST: 'user-list',
  GET_ALL_ROLES: 'all-roles',
  GET_ROLES: 'roles',
  GET_ALL_EVENT_SPRINT: 'event-sprint/all',
  EVENT_SPRINT: 'event-sprint',
  DELETE_EVENT_SPRINT: 'event-sprint/',
  GET_SKBUCKS_USER_LIST: 'user-list/sketchplay-bucks'
}

export const API_CUSTOMER_SUPPORT = {
  CUSTOMER_SUPPORT: 'customer-support',
}

export const API_EVENT = {
  EVENT: 'event',
  EVENT_LOCATION: 'event-location',
  EVENT_INTENT: 'event-player/event-intent',
  EVENT_INTENT_PLAN: 'event-player/event-intent/payment-plan',
  EVENT_REGISTER: 'event-player/event-payment',
  EVENT_REGISTER_FREE_PAYMENT: 'event-player/event-payment/free',
  EVENT_REGISTER_PAYMENT_PLAN: 'event-player/event-payment/payment-plan',
  EVENT_REGISTER_PAYMENT_OF_PRODUCT: 'event-player/event-payment/product',
  CHECK_EVENT_REGISTER: 'event-player/check-register',
  EVENT_INSURANCE: 'event/insurance',
  GET_EVENT_TYPE: 'event/event-type',
  EVENT_DAILY_TRAINING: 'event/daily-training',
  EVENT_ATHLET_LIST: 'event-player/athlete',
  EVENT_UN_REGISTER_USER: 'event-player/un-register',
  EVENT_USER_CHECK_IN: 'event-player/check-in',
  UPDATE_DAILT_TRAINING: 'event/update-dailytraining',
  DELETE_BULK_EVENT: 'event/bulk/remove',
  CHECK_LACROSSE_NUMBER: 'event/lacrosse/check',
  ALL_SUB_EVENTS: 'event/all/sub/events'
}

export const API_LEAGUE = {
  LEAGUE: 'league',
  LEAGUE_LOCATION: 'league/location',
  LEAGUE_DIVISION: 'league/division',
  REGISTER_TEAM: 'league/register/team',
  TEAM_PAYMENT: 'league/team',
  LEAGUE_INTENT: 'league/league-intent',
  LEAGUE_PAYMENT: 'league/league-payment',
  GET_LEAGUE_AGE_GROUP: 'league/age-group',
  TEAM_NOT_REGISTER_IN_LEAGUE: 'team/not/register',
  TEAM_APPROVAL_REQUEST: 'team/register/request',
  TEAM_DETAIL: 'team/deatil/with/price',
  LEAGUE_PAYMENT_DATA: 'league/league-hash-noauth',
  LEAGUE_PAYMENT_NO_AUTH: 'league/league-payment-noauth',
  LEAGUE_INTENT_NO_AUTH: 'league/league-intent-noauth',
  SEND_INVOICE: 'league/send-invoice',
  GET_LEAGUE_DOCUMENT_BY_ID: 'league/required/documents',
  GET_LEAGUE_AGE_GROUP_BY_ID: 'league/age/groups',
  CREATE_LEAGUE_TEAM_DOCUMENT: 'league/team-document',
  UPDATE_TEAM_STATUS: 'team/register/request/status',
  LEAGUE_EXPORTED_DATA: 'team/roster/documents',
  DIVISION_FILER_DATA: 'team/league',
  LEAGUE_SCORE: 'league-score',
  GET_LEAGUE_DOCUMENT: 'league/documents',
  CREATE_LEAGUE_DOCUMENT: 'league/common/document'
}

export const API_ATHLET = {
  ATHLET_USER: 'athlete/users',
}

export const API_DISCOUNT = {
  DISCOUNT: 'discount',
  DELETE_DISCOUNT: 'discount/',
  CHECK_DISCOUNT: 'discount/check',
  CHECK_SKBUCKS_DISCOUNT: 'discount/check-skb-discount'
}

export const API_PAYMENT = {
  PAYMENT_PLAN_DATES: 'payment-plan/event/date',
  CHECK_PAYMENT_PLAN_CALCULATION: 'payment-plan/event/calculation',
  PAYMENT_PLAN: 'payment-plan',
  PAYMENT_PLAN_ACTIVATE_DEACTIVATE: 'activate/deactivate',
  ORGANIZATION_PURCHASE: 'organization/purchase-history',
  HOUSEHOLD_FOR_PURCHASE: 'purchased/users',
  GET_PAYMENT_PLAN: 'payment-plan/plan-detail-history',
  GET_USER_DETAILS_PAY_PAGE: 'payment-plan/user/history',
  GET_PAYMENT_PLAN_DETAILS: 'payment-plan/register/users',
  GET_ADMIN_ORGANIZATION_FOR_PURCHASE: 'organization/admin/access/filter',
  GET_PACKAGE_FOR_PURCHASE: 'product/package/list/for/filter',
  GET_PLAN_FOR_PURCHASE: 'payment-plan/list/for/filter',
  GET_EVENT_FOR_PURCHASE: 'event-player/event/list/for/filter',
  UPDATE_PAYMENT_PLAN_REMAIN_AMOUNT: 'payment-plan/update-recurring-subscription'
}

export const API_FEATURE = {
  GET_FEATURE: 'features/category',
  GET_ACTIVE_FEATURE: 'features/list/active',
  GET_FEATURE_LIST: 'features/list',
  GET_ALL_STATES: 'country/states',
  GET_ALL_CITIES: 'country/cities',
  GET_GRADES: 'grade'
}

export const API_POST = {
  POST: 'post',
  GET_POST_COMMENTS: 'post/comments',
  COMMENT: 'comment',
  POST_COMMENT: 'post/comment',
  COMMENT_REPLY: 'comment/reply',
  COMMENT_REPLY_LIKE: 'comment/reply/like',
  COMMENT_LIKE: 'comment/like',
  POST_LIKE: 'post/like'
}

export const API_FILE_UPLOAD_AND_DOWNLOAD = {
  UPLOAD_FILE: 'media/file',
  UPLOAD_DOCUMENT: 'media/document',
  UPLOAD_VIDEO: 'media/video-thumbnail',
  UPLOAD_PROFILE_IMAGE: 'media/profile-picture',
  UPLOAD_BULK_DOCUMENT: 'media/bulk/document',
  UPLOAD_CSV_DATA: 'media/upload-data',
  UPLOAD_CSV_ROASTER: 'media/update-team-data',
  DOWNLOAD_ROASTER_TEMPLATE: 'media/download/roster/template',
  TEAM_ROASTER_TEMPLATE: 'team/roster-download',
  DOWNLOAD_ZIP: 'media/download/document',
  DOWNLOAD_USER: 'user/download',
  DOWNLOAD_REGISTERED_EVENT_USER: 'event-player/athlete/download',
  EXPORT_RECIPIENTS: 'email-campaign/export/recipients',
  EXPORT_RECIPIENTS_SMS: 'sms-campaign/export-recipient',
  EXPORT_CUSTOM_LIST: 'email-campaign/export/custom-list/users',
  DOWNLOAD_PURCHASE_PACK: 'product/export/data/purchase/pack',
  DOWNLOAD_SINGAL_EVENT: 'event-player/export/single/event/registration',
  DOWNLOAD_PAYMENT_PLAN: 'payment-plan/export/purchase/history',
  DOWNLOAD_SKUBUCKS_HISTORY: 'user-list/sketchplay-bucks/export'
}

export const API_HELP_VIDEO = {
  GET_HELP_VIDEO: 'help-video',
  GET_ROLE_WISE_VIDEO: 'help-video/role-wise'
}

export const API_MEMBER = {
  MEMBER: 'member',
  GET_SPORTS: 'organization/sports',
  GET_USER_BY_ID: 'users',
  DELETE_USER: 'user',
  ADD_USER: 'add/user',
  ADD_USER_IN_COMMUNITY: 'user/bulk/add/role/community',
  GET_SK_BUCKS_BALANCE: 'me/sketchplay-bucks',
  ADD_SK_BUCKS_CREDIT: 'member/add-credit-sk',
  GET_SK_BUCKS_ORG: 'organization/list/feature-on/'
}

export const API_ORGANIZATION = {
  ORGANIZATION: 'organization',
  DELETE_ORGANIZATION_USER: 'organization/remove-user',
  CHECK_DOMAIN: 'domain/check',
  UPDATE_ORGANIZATION_ACTIVE: 'webSetting/active',
  SUB_ORGANIZATION: 'organization/sub-organization',
  JOIN_ORGANIZATION: 'organization-joining-request',
  ORGANIZATION_ON_TOP: 'update/user/time',
  GET_PUBLIC_ORGANIZATION: 'organization/public-organization',
  CHECK_ACCESS_CODE: 'organization/with/access-code'
}

export const API_PRODUCT = {
  PRODUCT: 'product',
  GET_PRODUCT_LIST: 'product/list',
  DELETE_PRODUCT: 'product/package',
  DELETE_PRODUCT_PACK: 'product/pack',
  PRODUCT_CATEGORIES: 'product-categories',
  DELETE_CATEGORY: 'product/package/category',
  GET_ELIGIBLE_PRODUCT: 'product/eligible-product',
  CHECK_PRODUCT: 'product/check-product',
  ACTIVATE_DEACTIVATE_PRODUCT: 'product/package/deactivate',
  CHECK_PACKAGE_DISCOUNT: 'discount/package/check',
  PRODUCT_INTENT: 'product/product-intent',
  PRODUCT_PAYMENT: 'product/product-payment',
  UPDATE_PACK_COUNT: 'product/package-count'
}

export const API_WEB_SETTING = {
  WEB_SETTING: 'websetting',
  UPDATE_CONTENT_DETAILS: 'websetting/step',
  UPDATE_WEB_SETTING_ACTIVE: 'webSetting/active'
}

export const API_EMAIL = {
  GET_EMAIL_TEMPLATE: 'email-template',
  SEND_EMAIL: 'email-campaign',
  SEND_TEST_EMAIL: 'email-campaign/send/test/email',
  CREATE_CUSTOM_LIST: 'email-campaign/custom/list',
  GET_CUSTOM_DETAILS_BY_ID: 'email-campaign/custom-list',
  GET_LEAGUE_NAME: 'league/register/team/users',
  GET_TEAM_NAMES: 'team/with/users'
}

export const API_SMS = {

  POST_GET_SMS_CAMPAIGN: 'sms-campaign',
  GET_SMS_CAMPAIGN_BY_ID: 'sms-campaign',
  GET_FEATURE_ORGANIZATION: 'organization/list/feature-on/sms',
  GET_SMS_USERS_LIST: 'sms/users',
  GET_SMS_SETTING: 'sms-setting',
  SMS_SETTING_PAYMENT_INTENT: 'sms-setting/payment-intent',
  SMS_SETTING_SUBSCRIBE: 'sms-setting/subscription/create',
  DELETE_PLAN: 'sms-setting/send/subscription/cancel/request',
  GET_SMS_DETAIL: 'sms-setting/by/organization'

}

export const API_TEAM = {
  TEAM: 'team',
  ALL_POSITION: 'position',
  DELETE_COACH: 'team/remove/coach',
  DELETE_MANAGER: 'team/remove/manager',
  TEAM_ROSTER: 'team/roster',
  DELETE_ROSTER: 'team/remove/roster',
  GET_ROSTER_DOCUMENT: 'team/roster/document/list',
  ADD_ROSTER_DOCUMENT: 'team/roster/add/document',
  DELETE_ROSTER_DOCUMENT: 'team/remove/roster/document',
}

export const API_NOTIFICATION = {
  SEND_NOTIFICATION: 'user-notification/send-notification',
  GET_NOTIFICATION: 'user-notification',
  READ_NOTIFICATION: 'user-notification/read',
  GET_NOTIFICATION_SETTINGS: 'notification-settings/organization',
  UPDATE_NOTIFICATION_SETTINGS: 'notification-settings',
}

export enum EORG_FEATURE_TYPE {
  MARKETING = "marketing",
  COMMUNITY = "community",
  CALENDER = "calendar",
  CAMPS = "camps",
  CLINICS = "clinics",
  DAILY_TRAINING = "dailytraining",
  EVALUATIONS = "evaluations",
  FILMROOM = "filmroom",
  TEAM_MANAGEMENT = "teammanagement",
  LEAGUE_MANAGEMENT = "leaguemanagement",
  TOURNAMENTS = "tournaments",
  PRODUCT = "product",
  SKETCHPLAY_BUCKS = "sketchplaybuck",
  EMAIL = "email",
  SMS = "sms"
}
